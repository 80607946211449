/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReachSimulatorApi } from '@/api';
import { ReachSimulatorProjectForm } from '@/api/openapi';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

type createProjectReturnTypes = {
  createProject: (values: { form: ReachSimulatorProjectForm }) => Promise<void>;
  isCreatingProject: Ref<boolean>;
};

export const useCreateProject = (): createProjectReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();

  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }

  const _createProject = async ({
    form
  }: {
    form: ReachSimulatorProjectForm;
  }) => {
    try {
      const res = await ReachSimulatorApi.postCmPlanningCompaniesCompanyIdRsmProjects(
        Number(companyId),
        form
      );
      if (200 <= res.status && res.status < 300) {
        toast({
          title: '成功',
          message: 'プロジェクトの作成に成功しました',
          variant: 'success'
        });
        await router.push({
          name: PLANNING_ROUTES.rsm,
          params: route.params
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの作成に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの作成に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isCreatingProject, createProject] = useLoading(_createProject);

  return {
    createProject,
    isCreatingProject
  };
};
