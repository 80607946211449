/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReachSimulatorApi } from '@/api';
import {
  ReachSimulatorProject,
  ReachSimulatorProjectForm
} from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { isAfter } from 'date-fns';
import { Ref, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type updateProjectReturnTypes = {
  fetchProject: (params: any) => Promise<ReachSimulatorProject | undefined>;
  isFetchingProject: Ref<boolean>;
  updateProject: (values: any) => Promise<void>;
  isUpdatingProject: Ref<boolean>;
  addCopyString: (value: string) => string;
  isOpenConfirmUpdateModal: Ref<boolean>;
  confirmUpdate: (values: any) => void;
  isOpenCreateCopyModal: Ref<boolean>;
  copyTitle: Ref<string | undefined>;
  validCopyTitle: Ref<string | undefined>;
  createCopy: () => void;
};

export const useUpdateProject = (
  createProject?: (values: { form: ReachSimulatorProjectForm }) => Promise<void>
): updateProjectReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();
  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }
  const projectId = route.params.projectId?.toString();
  const addCopyString = (value: string) => {
    const str = value.length > 60 ? value.substring(0, 60) : value;
    return str + ' コピー';
  };
  const updateData: Ref<ReachSimulatorProjectForm | undefined> = ref();
  const isOpenConfirmUpdateModal = ref(false);
  const isOpenCreateCopyModal = ref(false);
  const copyTitle = ref();

  const _fetchProject = async (): Promise<
    ReachSimulatorProject | undefined
  > => {
    try {
      const res = await ReachSimulatorApi.getCmPlanningRsmProjectsProjectId(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        return res.data;
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの取得に失敗しました', '');
      } else if (e.status == 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの更新に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの取得に失敗しました',
          variant: 'error'
        });
      }
    }
  };

  const [isFetchingProject, fetchProject] = useLoading(_fetchProject);

  const _updateProject = async () => {
    if (!updateData.value) return;

    try {
      const res = await ReachSimulatorApi.putCmPlanningRsmProjectsProjectId(
        updateData.value.basicInfo.id ?? 0,
        updateData.value
      );
      if (200 <= res.status && res.status < 300) {
        await router.push({
          name: PLANNING_ROUTES.rsm,
          params: route.params
        });
        toast({
          title: '成功',
          message: 'プロジェクトの保存に成功しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの保存に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの保存に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの保存に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isUpdatingProject, updateProject] = useLoading(_updateProject);

  const confirmUpdate = async ({
    form,
    updateAt
  }: {
    form: ReachSimulatorProjectForm;
    updateAt: string;
  }) => {
    updateData.value = form;
    const latestData = await fetchProject(form.basicInfo.id);
    const latestDataUpdateAt = new Date(
      latestData?.basicInfo.updatedAt?.replace(/-/g, '/') ?? new Date()
    );
    if (!latestData) return;
    const editDataUpdateAt = new Date(updateAt.replace(/-/g, '/'));
    if (isAfter(latestDataUpdateAt, editDataUpdateAt)) {
      copyTitle.value = addCopyString(form.basicInfo.projectName);
      isOpenConfirmUpdateModal.value = true;
    } else {
      updateProject(undefined);
    }
  };

  const validCopyTitle = computed(() => {
    const value = copyTitle.value;
    if (!value) return undefined;
    if (value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (value.length > 0 && !value.match(/\S/g)) {
      return VALIDATION_MESSAGE.emptyFormat;
    }
    return undefined;
  });

  const createCopy = () => {
    isOpenCreateCopyModal.value = false;
    if (!createProject || !updateData.value) return;
    updateData.value.basicInfo.projectName = copyTitle.value;
    updateData.value.basicInfo.id = undefined;
    createProject({ form: updateData.value });
  };

  return {
    fetchProject,
    isFetchingProject,
    updateProject,
    isUpdatingProject,
    addCopyString,
    isOpenConfirmUpdateModal,
    confirmUpdate,
    isOpenCreateCopyModal,
    copyTitle,
    validCopyTitle,
    createCopy
  };
};
