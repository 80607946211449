import {
  addDays,
  addMonths,
  isAfter,
  isBefore,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns';

type ValueOf<T> = T[keyof T];

const getSeasonStartMonth = (month: number) => {
  switch (month) {
    case 1:
    case 2:
    case 3:
      return 1;
    case 4:
    case 5:
    case 6:
      return 4;
    case 7:
    case 8:
    case 9:
      return 7;
    case 10:
    case 11:
    case 12:
    default:
      return 10;
  }
};

// 今四半期を返す
const setCurrentQuarter = (basis: Date) => {
  const basisMonth = basis.getMonth() + 1;
  const currentQuarterStartYear = basis.getFullYear();
  const currentQuarterStartMonth = getSeasonStartMonth(basisMonth);
  const start = new Date(
    currentQuarterStartYear + '/' + currentQuarterStartMonth + '/' + '1'
  );
  const nextQuarterStart = addMonths(start, 3);
  const end = subDays(nextQuarterStart, 1);
  return { start: start, end: end };
};

// 前四半期を返す
const setLastQuarter = (basis: Date) => {
  const threeMonthsAgo = subMonths(basis, 3);
  const lastQuarter = setCurrentQuarter(threeMonthsAgo);
  return { start: lastQuarter.start, end: lastQuarter.end };
};

// クール:週単位の区間で、1 / 4 / 7 / 10月を4日以上含む最小の週を起点とし、次の起点の前週までの区間
// Coolsの名称はSMARTを踏襲

// 1,4,7,10月の1日からクールの開始日を返す
const getCoolsStart = (firstDay: Date) => {
  // 月曜から何日目か
  const dayOfWeekCount = firstDay.getDay() - 1 >= 0 ? firstDay.getDay() - 1 : 6;

  if (dayOfWeekCount <= 3) {
    return subDays(firstDay, dayOfWeekCount);
  } else {
    return addDays(firstDay, 7 - dayOfWeekCount);
  }
};

// 今クールを返す
const setCurrentCools = (basis: Date) => {
  let start = new Date();
  let end = new Date();
  const basicQuarterStart = setCurrentQuarter(basis).start;
  const previousQuarterStar = subMonths(basicQuarterStart, 3);
  const nextQuarterStart = addMonths(basicQuarterStart, 3);
  const afterNextQuarterStart = addMonths(nextQuarterStart, 3);
  const basicCoolsStart = getCoolsStart(basicQuarterStart);
  const previousCoolsStart = getCoolsStart(previousQuarterStar);
  const nextCoolsStart = getCoolsStart(nextQuarterStart);
  const afterNextCoolsStart = getCoolsStart(afterNextQuarterStart);
  const basicCoolsEnd = subDays(nextCoolsStart, 1);
  const previousCoolsEnd = subDays(basicCoolsStart, 1);
  const nextCoolsEnd = subDays(afterNextCoolsStart, 1);
  if (
    isAfter(basis, subDays(basicCoolsStart, 1)) &&
    isBefore(basis, nextCoolsStart)
  ) {
    start = basicCoolsStart;
    end = basicCoolsEnd;
  } else if (isBefore(basis, basicCoolsStart)) {
    start = previousCoolsStart;
    end = previousCoolsEnd;
  } else {
    start = nextCoolsStart;
    end = nextCoolsEnd;
  }
  return { start: start, end: end };
};

// 前クールを返す
const setLastCools = (basis: Date) => {
  const currentCoolsStart = setCurrentCools(basis).start;
  const lastCools = setCurrentCools(subDays(currentCoolsStart, 1));
  return { start: lastCools.start, end: lastCools.end };
};

// 当月を返す
const setCurrentMonth = (basis: Date) => {
  const start = startOfMonth(basis);
  const nextMonth = addMonths(basis, 1);
  const nextMonthStart = startOfMonth(nextMonth);
  const end = subDays(nextMonthStart, 1);
  return { start: start, end: end };
};

// 先月を返す
const setLastMonth = (basis: Date) => {
  const lastMonth = subMonths(basis, 1);
  const start = startOfMonth(lastMonth);
  const currentMonthStart = startOfMonth(basis);
  const end = subDays(currentMonthStart, 1);
  return { start: start, end: end };
};

// 直近の月曜日を返す
const geetLastMonday = (basis: Date) => {
  const dayOfWeekCount = basis.getDay() - 1 >= 0 ? basis.getDay() - 1 : 6;
  return subDays(basis, dayOfWeekCount);
};

// 前x週を返す
const setWeekAgo = (basis: Date, n: number) => {
  const lastMonday = geetLastMonday(basis);
  const days = 7 * n;
  const start = subDays(lastMonday, days);
  const end = subDays(lastMonday, 1);
  return { start: start, end: end };
};

// 前週を返す
const setLastWeek = (basis: Date) => {
  const lastWeek = setWeekAgo(basis, 1);
  return { start: lastWeek.start, end: lastWeek.end };
};

// 前4週を返す
const setFourWeeksAgo = (basis: Date) => {
  const fourWeeksAgo = setWeekAgo(basis, 4);
  return { start: fourWeeksAgo.start, end: fourWeeksAgo.end };
};

// 直近14日間を返す
const setLastFourteenDays = (basis: Date) => {
  const start = subDays(basis, 13);
  return { start: start, end: basis };
};

// 直近28日間を返す
const setLastTwentyEightDays = (basis: Date) => {
  const start = subDays(basis, 27);
  return { start: start, end: basis };
};

const setOrgPeriod = (start: Date, end: Date) => {
  return { start: start, end: end };
};

// 月曜-日曜で直近x週を返す
const setMostRecentWeek = (basis: Date, n: number) => {
  const lastMonday = geetLastMonday(basis);
  const days = 7 * n;
  const start = subDays(lastMonday, days);
  const end = addDays(lastMonday, 6);
  return { start: start, end: end };
};

export enum PeriodType {
  DefaultPeriods = 'DEFAULT_PERIODS',
  NearestPeriods = 'NEAREST_PERIODS'
}

const periods = {
  DEFAULT_PERIODS: {
    referMain: 'メインキャンペーンに合わせる',
    lastQuarter: '前四半期',
    lastCools: '前クール',
    currentCools: '今クール',
    lastMonth: '先月',
    fourWeeksAgo: '前四週',
    lastWeek: '前週',
    currentMonth: '当月',
    lastTwentyEightDays: '直近28日間',
    lastFourteenDays: '直近14日間',
    custom: '自動設定'
  },
  NEAREST_PERIODS: {
    lastOneWeek: '1週間',
    lastTwoWeeks: '2週間',
    lastThreeWeeks: '3週間',
    lastFourWeeks: '4週間',
    lastFiveWeeks: '5週間',
    lastSixWeeks: '6週間',
    lastSevenWeeks: '7週間',
    lastEightWeeks: '8週間'
  }
};

export const periodList = (periodType: PeriodType): PeriodListType[] => {
  const periodKeys = Object.keys(periods[periodType]) as Array<
    keyof typeof periods[typeof periodType]
  >;

  return periodKeys.map((key, i) => ({
    id: i.toString(),
    key: key,
    label: periods[periodType][key],
    isHiddenMain: key === 'referMain',
    isHiddenCustom: key === 'custom',
    $isDisabled: false,
    isFuture: key === 'currentCools' || key === 'currentMonth'
  }));
};

export type PeriodListType = {
  id: string;
  key: keyof typeof periods[PeriodType];
  label: ValueOf<typeof periods[PeriodType]>;
  isHiddenMain: boolean;
  isHiddenCustom: boolean;
  isFuture: boolean;
};

export const getPeriodList = (
  key: string,
  periodType: PeriodType | undefined = PeriodType.DefaultPeriods
): PeriodListType | undefined => {
  return periodList(periodType).find(period => period.key === key) as
    | PeriodListType
    | undefined;
};

export const setPeriod = (
  label: string,
  basis: Date,
  mainStart: Date,
  mainEnd: Date,
  customStart: Date,
  customEnd: Date
): { start: Date; end: Date } => {
  switch (label) {
    case periods[PeriodType.DefaultPeriods].referMain:
      return setOrgPeriod(mainStart, mainEnd);
    case periods[PeriodType.DefaultPeriods].lastQuarter:
      return setLastQuarter(basis);
    case periods[PeriodType.DefaultPeriods].lastCools:
      return setLastCools(basis);
    case periods[PeriodType.DefaultPeriods].currentCools:
      return setCurrentCools(basis);
    case periods[PeriodType.DefaultPeriods].lastMonth:
      return setLastMonth(basis);
    case periods[PeriodType.DefaultPeriods].fourWeeksAgo:
      return setFourWeeksAgo(basis);
    case periods[PeriodType.DefaultPeriods].lastWeek:
      return setLastWeek(basis);
    case periods[PeriodType.DefaultPeriods].currentMonth:
      return setCurrentMonth(basis);
    case periods[PeriodType.DefaultPeriods].lastTwentyEightDays:
      return setLastTwentyEightDays(basis);
    case periods[PeriodType.DefaultPeriods].lastFourteenDays:
      return setLastFourteenDays(basis);
    case periods[PeriodType.DefaultPeriods].custom:
      return setOrgPeriod(customStart, customEnd);
    case periods[PeriodType.NearestPeriods].lastOneWeek:
      return setMostRecentWeek(basis, 0);
    case periods[PeriodType.NearestPeriods].lastTwoWeeks:
      return setMostRecentWeek(basis, 1);
    case periods[PeriodType.NearestPeriods].lastThreeWeeks:
      return setMostRecentWeek(basis, 2);
    case periods[PeriodType.NearestPeriods].lastFourWeeks:
      return setMostRecentWeek(basis, 3);
    case periods[PeriodType.NearestPeriods].lastFiveWeeks:
      return setMostRecentWeek(basis, 4);
    case periods[PeriodType.NearestPeriods].lastSixWeeks:
      return setMostRecentWeek(basis, 5);
    case periods[PeriodType.NearestPeriods].lastSevenWeeks:
      return setMostRecentWeek(basis, 6);
    case periods[PeriodType.NearestPeriods].lastEightWeeks:
      return setMostRecentWeek(basis, 7);
    default:
      return setOrgPeriod(customStart, customEnd);
  }
};
