import { Ref } from 'vue';
import useToggle from './toggle';

const useAlert = (
  initialState = false
): {
  isAlertOpen: Ref<boolean>;
  openAlert: () => void;
  closeAlert: () => void;
} => {
  const [isAlertOpen, openAlert, closeAlert] = useToggle(initialState);
  return { isAlertOpen, openAlert, closeAlert };
};

export default useAlert;
