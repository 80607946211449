/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addDays,
  addWeeks,
  format,
  getHours,
  isBefore,
  subDays,
  subWeeks
} from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { CustomTargetApi, TvDataSearchApi } from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  ReachSimulatorBasicInfo,
  ReachSimulatorBasicInfoGoalTypeEnum,
  ReachSimulatorProject,
  ReachSimulatorProjectForm,
  RsmStationRateSetting,
  RsmStationRateSettingPlacementPatternEnum,
  RsmTargetSettings,
  RsmWeeklyGrpSetting,
  TroTargetSettingsTargets
} from '@/api/openapi';
import { PLANNING_ROUTES } from '@/router/planning';
import { useAreaOptions } from '@/store/areaOptions';

import { TARGET_NAME } from '@/common/constant';
import { getAvailableDate } from '@/common/dates';
import { DATE_FORMAT } from '@/common/format';
import { roundNumber } from '@/common/formatter';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { isDisabled } from '@/composables/validation';
import useAlert from '@/composables/alert';

// import { toast } from '@/components/ui/Toast';

interface GoalType {
  label: string;
  id: ReachSimulatorBasicInfoGoalTypeEnum;
}

interface weekNumberType {
  label: string;
  id: number;
}

interface stationSettingsType extends RsmStationRateSetting {
  label: string;
  stationPerCostStr: string;
  stationRateStr: string;
  pictureItem: pictureItemType;
}

interface weeklyGrpsType {
  weekNumber: number;
  weeklyGrp: string;
}

interface pictureItemType {
  label: string;
  id: RsmStationRateSettingPlacementPatternEnum;
  icon?: string;
}

interface UseValidatorReturnType {
  validTarget: Ref<string | undefined>;
  validGrp: Ref<string | undefined>;
  isFocusIndexWeeklyGrps: Ref<number | undefined>;
  validWeeklyGrps: Ref<string | undefined>;
  isFocusIndexStationSetting: Ref<number | undefined>;
  totalStationRate: Ref<number | undefined>;
  validStationSetting: Ref<string | undefined>;
  validTitle: Ref<string | undefined>;
  isDisabledSubmit: Ref<boolean>;
  validWeekNumber: Ref<string | undefined>;
  validPerCost: Ref<string | undefined>;
}

export interface InputFormType {
  targetName: Ref<string>;
  isCustomTarget: Ref<boolean>;
  basicTargetIds: Ref<string[] | undefined>;
  customTargetId: Ref<string | undefined>;
  initialTargetId: Ref<string[] | number[] | undefined>;
  goalType: Ref<ReachSimulatorBasicInfoGoalTypeEnum>;
  grp: Ref<string | undefined>;
  placementCost: Ref<string | undefined>;
  simulationStartDate: Ref<Date>;
  simulationEndDate: Ref<Date>;
  weeklyGrps: Ref<weeklyGrpsType[] | undefined>;
  stationSetting: Ref<stationSettingsType[] | undefined>;
  numOfEffectiveContacts: Ref<number>;
  updatedAt: Ref<string>;
  title: Ref<string>;
  projectId: Ref<number | undefined>;
}

export const GOAL_TYPES: Array<GoalType> = [
  {
    label: 'GRP',
    id: ReachSimulatorBasicInfoGoalTypeEnum.Grp
  },
  { label: '予算', id: ReachSimulatorBasicInfoGoalTypeEnum.Budget }
];

export enum EditType {
  create,
  edit,
  clone
}
export enum SubmitType {
  save,
  done
}

export const weekNumberItems: weekNumberType[] = [...Array(8)].map((_, i) => {
  const num = i + 1;
  return {
    id: num,
    label: num.toString() + '週間'
  };
});

export const numOfEffectiveContactsItems = [...Array(10)].map((_, i) => i + 1);

export const placementPatternItems: pictureItemType[] = [
  {
    label: '全日',
    id: RsmStationRateSettingPlacementPatternEnum.All,
    icon: 'rectangle'
  },
  {
    label: 'ヨの字',
    id: RsmStationRateSettingPlacementPatternEnum.Yo,
    icon: 'box-drawing-yo'
  },
  {
    label: 'コの字',
    id: RsmStationRateSettingPlacementPatternEnum.Ko,
    icon: 'box-drawing-ko'
  },
  {
    label: '逆L',
    id: RsmStationRateSettingPlacementPatternEnum.Rl,
    icon: 'box-drawing-reverse-l'
  },
  {
    label: '一の字',
    id: RsmStationRateSettingPlacementPatternEnum.Ichi,
    icon: 'box-drawing-ichi'
  }
];

export const STATION_RATE_MAP = {
  SAAS_KANTO: {
    NTV: 25,
    EX: 25,
    TBS: 20,
    TX: 10,
    CX: 20
  },
  SAAS_KANSAI: {
    YTV: 25,
    ABC: 25,
    MBS: 20,
    TVO: 5,
    KTV: 25
  },
  SAAS_CHUKYO: {
    CTV: 25,
    NBN: 25,
    CBC: 20,
    TVA: 5,
    THK: 25
  }
};

export const STATION_PER_COST_MAP = {
  SAAS_KANTO: {
    NTV: '160000',
    EX: '150000',
    TBS: '150000',
    TX: '130000',
    CX: '160000'
  },
  SAAS_KANSAI: {
    YTV: '44000',
    ABC: '41000',
    MBS: '41000',
    TVO: '36000',
    KTV: '44000'
  },
  SAAS_CHUKYO: {
    CTV: '27000',
    NBN: '25000',
    CBC: '25000',
    TVA: '22000',
    THK: '27000'
  }
};

const deleteComma = (str: string): string => {
  return str.replace(/,/g, '');
};

export const castNum = (str: string | undefined): number | undefined => {
  if (!str) return;
  const val = deleteComma(str);
  return Number(val);
};

const convertTargetName = (value: string): string => {
  switch (value) {
    case TARGET_NAME.customTarget:
      return 'CUSTOM';
    case TARGET_NAME.individual:
      return 'INDIVIDUAL';
    case TARGET_NAME.genderAge12Type:
      return 'BASIC';
    case TARGET_NAME.gender10Type:
      return 'BASIC';
    default:
      return 'INDIVIDUAL';
  }
};

const getTargetName = (targetType: string) => {
  switch (targetType) {
    case 'CUSTOM':
      return TARGET_NAME.customTarget;
    case 'INDIVIDUAL':
      return TARGET_NAME.individual;
    case 'BASIC_GA12':
      return TARGET_NAME.genderAge12Type;
    case 'BASIC_GA10S':
      return TARGET_NAME.gender10Type;
    default:
      return TARGET_NAME.individual;
  }
};

// 入力フォーム
export const useInputForm = (
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  areaOptions: ReturnType<typeof useAreaOptions>,
  areaList: Ref<string[]>,
  route: ReturnType<typeof useRoute>,
  router: ReturnType<typeof useRouter>,
  onChangeArea: () => Promise<void>,
  tvStations: Ref<{ code: string; label: string }[]>,
  companyId: Ref<number>,
  editType: EditType,
  submit: (form: ReachSimulatorProjectForm, updateAt?: string) => void
): any => {
  // 入力フォームデータ
  const inputform: InputFormType = {
    targetName: ref('個人全体'),
    isCustomTarget: ref(false),
    basicTargetIds: ref(),
    customTargetId: ref(),
    initialTargetId: ref(),
    goalType: ref<ReachSimulatorBasicInfoGoalTypeEnum>(
      ReachSimulatorBasicInfoGoalTypeEnum.Grp
    ),
    grp: ref(),
    placementCost: ref(),
    simulationStartDate: ref(new Date()),
    simulationEndDate: ref(new Date()),
    weeklyGrps: ref(),
    numOfEffectiveContacts: ref(1),
    stationSetting: ref(),
    updatedAt: ref(''),
    title: ref(''),
    projectId: ref<number | undefined>()
  };
  const weekNumber = computed(() => {
    const diffInMillis =
      addDays(inputform.simulationEndDate.value, 1).getTime() -
      inputform.simulationStartDate.value.getTime();
    return Math.round(diffInMillis / (1000 * 60 * 60 * 24 * 7));
  });
  const grpNum = computed(() => {
    return castNum(inputform.grp.value);
  });
  const placementCostNum = computed(() => {
    return castNum(inputform.placementCost.value);
  });

  // inputタイプフォーム関連
  const GoalType = GOAL_TYPES.map(v => ({
    label: v.label,
    value: v.id
  }));
  const resetInputData = () => {
    inputform.grp.value = undefined;
    inputform.placementCost.value = undefined;
    setAvgWeeklyGrps();
  };
  // [GRPモード]合計出稿予算（各放送局ごとのGRP*パーコストの合算）
  const setPlacementCostForGrp = () => {
    if (
      inputform.goalType.value !== ReachSimulatorBasicInfoGoalTypeEnum.Grp ||
      !grpNum.value
    ) {
      inputform.placementCost.value = '0';
      return;
    }
    let placementCost = 0;
    inputform.stationSetting.value?.forEach(station => {
      const stationGrp = getStationGrpByTotalGrp(station.stationRate);
      const stationPerCost = castNum(station.stationPerCostStr) ?? 0;
      placementCost += stationGrp * stationPerCost;
    });
    inputform.placementCost.value = Math.round(placementCost).toLocaleString();
  };
  // [予算モード]合計GRPを設定
  const setGrpForGrpForBudget = () => {
    const grp = calculateGrpForBudget();
    inputform.grp.value = Math.round(grp).toLocaleString();
  };
  // [予算モード]合計GRPを算出（各放送局ごとのGRPの合算）
  const calculateGrpForBudget = () => {
    if (
      inputform.goalType.value !== ReachSimulatorBasicInfoGoalTypeEnum.Budget ||
      !placementCostNum.value
    ) {
      return 0;
    }
    let grp = 0;
    inputform.stationSetting.value?.forEach(station => {
      grp += getStationGrpByTotalBudget(
        station.stationRate,
        station.stationPerCost
      );
    });
    return grp;
  };

  // 週数フォーム関連
  const endMaxDate = ref(new Date());
  const minDate = ref(new Date());
  const lastDay = computed(() => {
    const days = weekNumber.value * 7 - 1;
    const last = addDays(inputform.simulationStartDate.value, days);
    return format(last, DATE_FORMAT);
  });
  const datePickerKey = ref(0);
  const setSearchStartDate = () => {
    const today = new Date();
    // 確報日付取得
    const hour = getHours(today);
    const finalReportDay =
      0 <= hour && hour < 5 ? subDays(today, 4) : subDays(today, 3);

    let finalReportMonday = finalReportDay;
    for (let i = 0; i < 7; i++) {
      // 確報日付に一番近い過去の月曜を取得
      if (finalReportMonday.getDay() === 1) {
        break;
      }
      finalReportMonday = subDays(finalReportMonday, 1);
    }
    if (finalReportDay.getDay() !== 0) {
      finalReportMonday = subWeeks(finalReportMonday, 1);
    }
    finalReportMonday.setHours(9, 0, 0, 0);

    inputform.simulationStartDate.value = subWeeks(finalReportMonday, 3);
    inputform.simulationEndDate.value = addDays(finalReportMonday, 6);
    endMaxDate.value = inputform.simulationEndDate.value;
  };
  const setMinDate = () => {
    minDate.value = getAvailableDate(areaValue.value as AreaInfoIdEnum);
    if (isBefore(inputform.simulationStartDate.value, minDate.value)) {
      let tmpDate = minDate.value;
      for (let i = 0; i <= 365; i++) {
        if (tmpDate.getDay() == 1) {
          inputform.simulationStartDate.value = tmpDate;
          break;
        }
        tmpDate = addDays(tmpDate, 1);
      }
    }
    datePickerKey.value += 1;
  };

  // 週別GRPフォーム関連
  const setAvgWeeklyGrps = () => {
    const weekCount = weekNumber.value;
    const grp = grpNum.value ? grpNum.value : 0;
    const avg = Math.floor(Number(grp) / weekCount);
    inputform.weeklyGrps.value = [];
    for (let i = 1; i <= weekCount; i++) {
      let avgWeeklyGrps = avg;
      // 最終週で端数調整
      if (i !== 1 && i === weekCount) {
        avgWeeklyGrps = grp - avg * (weekCount - 1);
      }
      inputform.weeklyGrps.value.push({
        weekNumber: i,
        weeklyGrp: roundNumber(avgWeeklyGrps, 1)
      });
    }
  };

  // 局設定フォーム関連
  const initStationSetting = () => {
    inputform.stationSetting.value = [];
    tvStations.value.map(v => {
      const staionPerCost = areaValue.value
        ? STATION_PER_COST_MAP[areaValue.value][v.code]
        : 0;
      const stationRate = areaValue.value
        ? STATION_RATE_MAP[areaValue.value][v.code]
        : 0;
      inputform.stationSetting.value?.push({
        stationCode: v.code,
        label: v.label,
        placementPattern: placementPatternItems[0].id,
        pictureItem: placementPatternItems[0],
        stationPerCostStr: staionPerCost.toString(),
        stationPerCost: staionPerCost,
        stationRateStr: stationRate.toString(),
        stationRate: stationRate
      });
    });
  };
  const resetStationRate = () => {
    inputform.stationSetting.value?.forEach(station => {
      station.stationRateStr = areaValue.value
        ? STATION_RATE_MAP[areaValue.value][station.stationCode].toString()
        : '0';
      station.stationRate = areaValue.value
        ? STATION_RATE_MAP[areaValue.value][station.stationCode]
        : 0;
    });
  };
  const setAvgStationSetting = () => {
    const num = tvStations.value.length;
    const avg = Math.ceil(100 / num);
    inputform.stationSetting.value = inputform.stationSetting.value?.map(v => ({
      ...v,
      stationRateStr: avg.toString(),
      stationRate: avg
    }));
  };
  // [GRPモード]各局のGRP（合計GRP*GRP比率）
  const getStationGrpByTotalGrp = (rate: number | undefined): number => {
    if (!grpNum.value || !rate) return 0;
    return (grpNum.value * rate) / 100;
  };
  // [予算モード]各局のGRP（合計出稿予算*予算比率/パーコスト）
  const getStationGrpByTotalBudget = (
    rate: number | undefined,
    perCost: number
  ): number => {
    if (!placementCostNum.value || !rate || !perCost) return 0;
    const stationCost = (placementCostNum.value * rate) / 100;
    return stationCost / perCost;
  };

  // 戻るボタン
  const cancel = () => {
    router.push({
      name: PLANNING_ROUTES.rsm,
      params: {
        companyId: companyId.value
      }
    });
  };

  const doSubmit = () => {
    // 四捨五入前の合計GRPを取得、合計GRPと合計出稿予算から平均パーコストを算出
    const totalGrp =
      inputform.goalType.value === ReachSimulatorBasicInfoGoalTypeEnum.Grp
        ? grpNum.value ?? 0
        : calculateGrpForBudget();
    const totalPlacementCost = placementCostNum.value ?? 0;
    const averagePerCost = totalGrp === 0 ? 0 : totalPlacementCost / totalGrp;
    const basicInfo: ReachSimulatorBasicInfo = {
      id: inputform.projectId.value,
      projectName: inputform.title.value,
      authorSaasAccountId: undefined,
      area: areaValue.value ?? '',
      goalType: inputform.goalType.value,
      grp: totalGrp,
      placementCost: totalPlacementCost,
      averagePerCost: Math.round(averagePerCost),
      simulationStartDate: format(
        inputform.simulationStartDate.value,
        DATE_FORMAT
      ),
      numOfEffectiveContacts: inputform.numOfEffectiveContacts.value,
      simulationStatus: undefined
    };

    let targetSettingsTargets: TroTargetSettingsTargets[] = [];
    if (inputform.isCustomTarget.value) {
      targetSettingsTargets = [
        { targetId: Number(inputform.customTargetId.value) }
      ];
    } else {
      if (inputform.basicTargetIds.value) {
        inputform.basicTargetIds.value?.map(v => {
          targetSettingsTargets.push({
            targetId: Number(v)
          });
        });
      }
    }
    const targetSetting: RsmTargetSettings = {
      targetType: convertTargetName(inputform.targetName.value),
      targets: targetSettingsTargets,
      targetIsEnabled: undefined
    };

    const weeklyGrpSetting: RsmWeeklyGrpSetting[] = inputform.weeklyGrps.value
      ? inputform.weeklyGrps.value?.map(v => ({
          weekNumber: v.weekNumber,
          weeklyGrp: castNum(v.weeklyGrp) ?? 0
        }))
      : [];

    const stationSetting: RsmStationRateSetting[] = inputform.stationSetting
      .value
      ? inputform.stationSetting.value.map(v => ({
          stationCode: v.stationCode,
          placementPattern: v.pictureItem.id,
          stationPerCost: v.stationPerCost,
          stationRate: (() => {
            if (
              inputform.goalType.value ===
              ReachSimulatorBasicInfoGoalTypeEnum.Grp
            ) {
              return v.stationRate;
            } else {
              if (!totalGrp) return 0;
              return (
                (getStationGrpByTotalBudget(v.stationRate, v.stationPerCost) /
                  totalGrp) *
                100
              );
            }
          })()
        }))
      : [];

    const form: ReachSimulatorProjectForm = {
      basicInfo: basicInfo,
      targetSetting: targetSetting,
      weeklyGrps: weeklyGrpSetting,
      stationSettings: stationSetting
    };

    submit(form, inputform.updatedAt.value);
  };

  const convertToInputFormData = (data: ReachSimulatorProject | undefined) => {
    if (!data) return;

    inputform.targetName.value = getTargetName(data.targetSetting.targetType);
    if (data.targetSetting.targetType === 'CUSTOM') {
      inputform.customTargetId.value = data.targetSetting.targets[0].targetId.toString();
      inputform.isCustomTarget.value = true;
    } else if (data.targetSetting.targetType === 'INDIVIDUAL') {
      inputform.isCustomTarget.value = false;
    } else {
      inputform.basicTargetIds.value = data.targetSetting.targets.map(v => {
        return v.targetId.toString() ?? '0';
      });
      inputform.isCustomTarget.value = false;
    }
    inputform.initialTargetId.value = data.targetSetting.targets.map(
      v => v.targetId
    );

    inputform.goalType.value = data.basicInfo.goalType;
    inputform.grp.value = roundNumber(data.basicInfo.grp, 1);
    inputform.placementCost.value =
      data.basicInfo.placementCost || data.basicInfo.placementCost === 0
        ? roundNumber(data.basicInfo.placementCost, 1)
        : undefined;
    setSearchStartDate();
    inputform.simulationStartDate.value = new Date(
      data.basicInfo.simulationStartDate
    );
    inputform.simulationEndDate.value = subDays(
      addWeeks(inputform.simulationStartDate.value, data.weeklyGrps.length),
      1
    );

    inputform.numOfEffectiveContacts.value =
      data.basicInfo.numOfEffectiveContacts;
    inputform.weeklyGrps.value = data.weeklyGrps.map(v => ({
      weekNumber: v.weekNumber,
      weeklyGrp: roundNumber(v.weeklyGrp, 1)
    }));

    inputform.stationSetting.value = inputform.stationSetting.value?.map(v => {
      const apiVal = data.stationSettings.find(
        setting => setting.stationCode === v.stationCode
      );
      const pictureItem = placementPatternItems.find(
        item => item.id === apiVal?.placementPattern
      );
      const stationPerCost = apiVal?.stationPerCost ?? v.stationPerCost;
      let stationRate = 0;
      if (
        inputform.goalType.value === ReachSimulatorBasicInfoGoalTypeEnum.Grp
      ) {
        stationRate = apiVal?.stationRate ?? 0;
      } else {
        stationRate = calculateBudgetRate(
          data.basicInfo.grp,
          data.basicInfo.placementCost ?? 0,
          stationPerCost,
          apiVal?.stationRate ?? 0
        );
      }
      return {
        ...v,
        stationPerCost: stationPerCost,
        stationPerCostStr: stationPerCost.toString(),
        stationRate: stationRate,
        stationRateStr: stationRate.toString(),
        pictureItem: pictureItem ?? placementPatternItems[0]
      };
    });

    inputform.updatedAt.value = data.basicInfo.updatedAt ?? '';
    inputform.title.value = data.basicInfo.projectName;
    inputform.projectId.value = data.basicInfo.id;
  };

  const calculateBudgetRate = (
    grp: number,
    placementCost: number,
    perCost: number,
    grpRate: number
  ): number => {
    if (!placementCost) {
      return 0;
    }
    const stationGrp = grp * (grpRate / 100);
    const stationBudget = stationGrp * perCost;
    return Math.round((stationBudget / placementCost) * 100);
  };

  // 初期処理
  const created = async (
    customTargets: Ref<CustomTarget[]>,
    projectData: ReachSimulatorProject | undefined
  ): Promise<void> => {
    try {
      // エリア取得
      areaValue.value = await areaOptions.fetch(
        route,
        undefined,
        undefined,
        true
      );
      if (projectData?.basicInfo.area) {
        areaValue.value = projectData.basicInfo.area as AreaInfoIdEnum;
      }
      await onChangeArea();
      setSearchStartDate(); // 週数初期値設定
      setAvgWeeklyGrps(); // 週別GRP初期値設定
      initStationSetting(); // 局設定初期値設定
      setMinDate();
      // カスタムターゲット一覧設定
      const getCustomTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
        companyId.value
      );
      customTargets.value = getCustomTargets.data.list;

      if (editType !== EditType.create) {
        // 編集データの当て込み
        convertToInputFormData(projectData);
        // 編集データのエリアが契約エリアに含まれているか判定
        if (!areaList.value.includes(projectData?.basicInfo.area ?? '')) {
          areaValue.value = undefined;
        }
      }
    } catch (e) {
      // if (e.state !== httpCode.forbidden) {
      //   toast({ title: '失敗', message: e.message, variant: 'error' });
      // }
    }
  };

  const getStationSettingTitle = computed(() => {
    if (inputform.goalType.value === ReachSimulatorBasicInfoGoalTypeEnum.Grp) {
      return 'GRP比率';
    } else {
      return '予算比率';
    }
  });

  return {
    inputform,
    GoalType,
    datePickerKey,
    endMaxDate,
    minDate,
    lastDay,
    setMinDate,
    resetInputData,
    setPlacementCostForGrp,
    setGrpForGrpForBudget,
    setSearchStartDate,
    setAvgWeeklyGrps,
    initStationSetting,
    resetStationRate,
    setAvgStationSetting,
    getStationGrpByTotalGrp,
    getStationGrpByTotalBudget,
    cancel,
    doSubmit,
    created,
    getStationSettingTitle,
    weekNumber
  };
};

// エリア
export const useArea = (
  areaOptionStore: ReturnType<typeof useAreaOptions>,
  areaValue: Ref<AreaInfoIdEnum>,
  companyId: Ref<number>
): any => {
  const { selectOptions: areaSelectOptions } = storeToRefs(areaOptionStore);
  const tvStations = ref<{ code: string; label: string }[]>([]);
  const onChangeArea = async () => {
    // 放送局取得
    const res = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      true
    );
    tvStations.value = [...res.data].map(v => ({
      code: v.stationCodes[0],
      label: v.displayStationName
    }));
  };
  const areaList = computed(() =>
    areaSelectOptions.value.flatMap(v => v.areas.map(area => area.id))
  );
  return {
    areaSelectOptions,
    areaList,
    onChangeArea,
    tvStations
  };
};

// ターゲット
export const useTargets = (inputform: InputFormType): any => {
  const customTargets = ref<CustomTarget[]>([]);
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  const onChangeTarget = (target: { name: string; value: any }) => {
    inputform.targetName.value = target.name;
    if (inputform.targetName.value === TARGET_NAME.individual) {
      inputform.isCustomTarget.value = false;
      inputform.basicTargetIds.value = undefined;
      inputform.customTargetId.value = undefined;
      return;
    }
    if (inputform.targetName.value === TARGET_NAME.customTarget) {
      inputform.isCustomTarget.value = true;
      inputform.basicTargetIds.value = undefined;
      const targetValue = Array.isArray(target.value)
        ? target.value[0]
        : target.value;
      if (targetValue && targetValue.id)
        inputform.customTargetId.value = targetValue.id;
      return;
    }
    inputform.isCustomTarget.value = false;
    inputform.customTargetId.value = undefined;
    if (target.value) inputform.basicTargetIds.value = target.value;
  };

  return {
    customTargets,
    customTargetList,
    onChangeTarget
  };
};

// バリデーション
export const useValidator = (
  inputform: InputFormType,
  customTargetList: Ref<
    {
      id: number;
      label: string;
      isEnabled: boolean | undefined;
    }[]
  >,
  areaValue: Ref<AreaInfoIdEnum | undefined>
): UseValidatorReturnType => {
  const validTarget = computed(() => {
    const targetName = inputform.targetName.value;
    const customTargetId = inputform.customTargetId.value;
    const basicTargetIds = inputform.basicTargetIds.value;
    if (targetName === TARGET_NAME.individual) {
      return undefined;
    }
    if (targetName === TARGET_NAME.customTarget) {
      if (customTargetId === undefined) {
        return 'いずれか一つは選択してください。';
      } else {
        const target = customTargetList.value.find(
          v => v.id.toString() == customTargetId
        );
        if (!target) {
          return '別のターゲットを指定してください。';
        }
        return !target?.isEnabled
          ? '別のターゲットを指定するか、カスタムターゲットを修正してください。'
          : undefined;
      }
    }
    return basicTargetIds?.length === 0
      ? 'いずれか一つは選択してください。'
      : undefined;
  });

  const validGrp = computed(() => {
    const grp = castNum(inputform.grp.value);
    if (grp === undefined || grp === null) return undefined;

    if (100 > grp || grp > 20000) {
      switch (inputform.goalType.value) {
        case ReachSimulatorBasicInfoGoalTypeEnum.Grp:
          return VALIDATION_MESSAGE.invalidNumberWidth(100, 20000);
        case ReachSimulatorBasicInfoGoalTypeEnum.Budget:
          return '合計GRPが100~20000になるよう設定してください';
        default:
          return undefined;
      }
    }

    return undefined;
  });

  const isFocusIndexWeeklyGrps = ref();

  const validWeeklyGrps = computed(() => {
    const grp = castNum(inputform.grp.value);
    if (!grp && grp !== 0) return undefined;
    let sum = 0;
    inputform.weeklyGrps.value?.map(v => {
      const wGrm = castNum(v.weeklyGrp) ?? 0;
      sum = sum + wGrm;
    });
    if (sum !== grp) {
      let message = '合計：' + sum;
      if (sum > grp) {
        message += ' （' + (sum - grp) + ' 超過）';
      } else {
        message += ' （' + (grp - sum) + ' 不足）';
      }
      return message;
    }
    return undefined;
  });

  const isFocusIndexStationSetting = ref();

  const totalStationRate = computed(() => {
    let sum = 0;
    inputform.stationSetting.value?.map(v => {
      if (!v.stationRate) return;
      sum = sum + v.stationRate;
    });
    return sum;
  });

  const validStationSetting = computed(() => {
    if (totalStationRate.value !== 100 && areaValue.value) {
      let message = '';
      if (totalStationRate.value > 100) {
        message = '(' + (totalStationRate.value - 100) + '% 超過)';
      } else {
        message = '(' + (100 - totalStationRate.value) + '% 不足)';
      }
      return message;
    }
    return undefined;
  });

  const validTitle = computed(() => {
    const value = inputform.title.value;
    if (value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (value.length > 0 && !value.match(/\S/g)) {
      return VALIDATION_MESSAGE.emptyFormat;
    }
    return undefined;
  });

  const validWeekNumber = computed(() => {
    if (!inputform.weeklyGrps.value) {
      return undefined;
    } else if (inputform.weeklyGrps.value.length > 8) {
      return '期間が8週間以内となるように設定してください';
    }
    return undefined;
  });

  const validPerCost = computed(() => {
    let message: string | undefined = undefined;
    inputform.stationSetting.value?.forEach(v => {
      if (
        !v.stationPerCost ||
        v.stationPerCost <= 0 ||
        v.stationPerCost >= 1_000_000
      ) {
        message = '1~999,999円の値で設定してください';
      }
    });
    return message;
  });

  const disabled = isDisabled([
    validTarget,
    validGrp,
    validWeeklyGrps,
    validStationSetting,
    validTitle,
    validWeekNumber,
    validPerCost
  ]);
  const isDisabledSubmit = computed(() => {
    const check = () => {
      if (
        inputform.goalType.value === ReachSimulatorBasicInfoGoalTypeEnum.Grp
      ) {
        return !inputform.grp.value;
      } else {
        return !inputform.placementCost.value;
      }
    };
    return (
      disabled.value ||
      check() ||
      inputform.title.value === '' ||
      !areaValue.value
    );
  });

  return {
    validTarget,
    validGrp,
    isFocusIndexWeeklyGrps,
    validWeeklyGrps,
    isFocusIndexStationSetting,
    totalStationRate,
    validStationSetting,
    validTitle,
    isDisabledSubmit,
    validWeekNumber,
    validPerCost
  };
};

// LocalStorageからパーコストアラート非表示フラグを取得・設定
const getPerCostAlertDisableFlag = () => {
  const flag = localStorage.getItem('alertDisableFlagForRsmPercost');
  if (flag === null) {
    return false;
  } else {
    return flag === 'true';
  }
};
const setPerCostAlertDisableFlag = (flag: boolean) => {
  localStorage.setItem('alertDisableFlagForRsmPercost', flag.toString());
};

// アラート表示用
const { isAlertOpen, closeAlert } = useAlert(!getPerCostAlertDisableFlag());

// パーコストアラート
export const usePerCostAlert = (): any => {
  const closePerCostAlert = () => {
    closeAlert();
    setPerCostAlertDisableFlag(true);
  };
  return {
    isAlertOpen,
    closePerCostAlert
  };
};
